import React, { useContext, useState } from 'react';
import { ChatbotSubscriptionContext } from './ChatbotSubscriptionContext'; // Adjust the import path as necessary
import {
  Box,
  Typography,
  TextField,
  Card,
  CardContent,
  Button,
  Grid
} from '@mui/material';

const SubscribedChatbots = () => {
  const { subscribedChatbots, fetchSubscribedChatbots, fetchChatbots } = useContext(ChatbotSubscriptionContext);
  const [searchTerm, setSearchTerm] = useState('');

  // Filter chatbots based on the search term
  const filteredChatbots = subscribedChatbots.filter(bot =>
    bot.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    bot.shortDescription.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleUnsubscribe = async (chatbotId) => {
    try {
      const token = localStorage.getItem('token'); // Retrieve token from localStorage

      if (!token) {
        alert('You must be logged in to unsubscribe from a chatbot.');
        return;
      }

      const response = await fetch(`https://dellfi.serv.uni-hohenheim.de/backend/chatbots/subscriptions/${chatbotId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (!response.ok) {
        const errorData = await response.json();
        console.error('Error unsubscribing from chatbot:', errorData);
        alert(`Failed to unsubscribe from chatbot: ${errorData.message || 'Unknown error'}`);
        return;
      }

      console.log('Unsubscribed from chatbot successfully');
      // Refetch the subscribed and public chatbots to refresh the context
      fetchSubscribedChatbots();
      fetchChatbots();
    } catch (error) {
      console.error('Error during unsubscription:', error);
      alert('An error occurred while trying to unsubscribe from the chatbot.');
    }
  };

  return (
    <Box sx={{ padding: 3, backgroundColor: '#fff', borderRadius: 2, boxShadow: 1 }}>
      <Typography variant="h5" gutterBottom>
        My Subscribed Chatbots
      </Typography>

      <TextField
        label="Search subscribed chatbots"
        variant="outlined"
        fullWidth
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        sx={{ marginBottom: 3 }}
      />

      <Grid container spacing={2}>
        {filteredChatbots.map((bot) => (
          <Grid item xs={12} sm={6} key={bot.id}>
            <Card variant="outlined">
              <CardContent>
                <Typography variant="h6">{bot.name}</Typography>
                <Typography variant="body2" color="text.secondary" sx={{ marginBottom: 2 }}>
                  {bot.shortDescription}
                </Typography>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => handleUnsubscribe(bot.id)}
                >
                  Unsubscribe
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default SubscribedChatbots;
