import React, { useState, useEffect } from 'react';
import './FileUploader.css';

const ALLOWED_TYPES = `
  image/jpeg,
  image/png,
  text/plain,
  application/pdf,
  application/vnd.openxmlformats-officedocument.presentationml.presentation,
  video/mp4,
  application/vnd.openxmlformats-officedocument.wordprocessingml.document,
  application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,
  text/markdown,
  text/csv,
  application/json,
  application/jsonlines,
  audio/mpeg,
  audio/wav,
  text/html
`.replace(/\s+/g, '');

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

async function computeChecksum(file) {
    const fileBuffer = await file.arrayBuffer();
    const hashBuffer = await crypto.subtle.digest('SHA-256', fileBuffer);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');
    return hashHex;
}

async function uploadFileChunk(blob, name, start, end, totalSize, folder, checksum, maxRetries = 3) {
    let attempts = 0;

    if(folder == "chatbots"){
        const errorMessage = "You cannot upload files directly to the chatbots folder. Please upload to a subfolder.";
        throw new Error(errorMessage);
    }

    while (attempts < maxRetries) {
        try {
            const formData = new FormData();
            formData.append('checksum', checksum);
            formData.append('file', blob);
            formData.append('filename', name);
            formData.append('folder', folder);
            formData.append('totalSize', totalSize)

            const token = localStorage.getItem("token");
            const response = await fetch('https://dellfi.serv.uni-hohenheim.de/backend/upload', {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Range': `bytes ${start}-${end - 1}/${totalSize}`,
                    'Content-Length': end - start
                },
                body: formData
            });

            if (response.ok) {
                console.log("Transmitted chunk!");
                return await response.json();
            } else {
                const errorMessage = await response.text();
                throw new Error(`Server responded with ${response.status}: ${errorMessage}`);
            }

        } catch (error) {
            attempts++;
            const backoffTime = Math.pow(2, attempts) * 1000;
            console.error(`Error uploading chunk, attempt ${attempts} of ${maxRetries}:`, error.message);
            console.error(`Error uploading chunk, waiting for ${backoffTime}ms before retrying...`, error.message);
            await sleep(backoffTime);
            if (attempts >= maxRetries) {
                throw new Error(`Failed to upload chunk after ${maxRetries} attempts. ${error.message}`);
            }
        }
    }
}

async function checkFolderExists(targetFolder) {
    const token = localStorage.getItem("token");
    try {
        const response = await fetch(`https://dellfi.serv.uni-hohenheim.de/backend/metadata?path=${targetFolder}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        if (response.ok) {
            return true;
        } else {
            return false;
        }
    } catch (error) {
        console.error("Error checking folder:", error.message);
        return false;
    }
}

function FileUploader({ multiple = false, folder = '/', onUploadComplete }) {
    const [files, setFiles] = useState([]);
    const [currentFileProgress, setCurrentFileProgress] = useState(0);
    const [totalProgress, setTotalProgress] = useState(0);
    const [errorMessage, setErrorMessage] = useState('');

     useEffect(() => {
        if (errorMessage) {
            const timer = setTimeout(() => {
                setErrorMessage('');
            }, 7000);
            return () => clearTimeout(timer);
        }
    }, [errorMessage]);

    const onFileChange = (e) => {
        setErrorMessage(''); // Clear error message on file change
        if (multiple) {
            setFiles([...e.target.files]);
        } else {
            setFiles([e.target.files[0]]);
        }
    };

    const onUpload = async () => {
        // Before initiating upload, check if folder exists
        const folderExists = await checkFolderExists(folder);
        if (!folderExists) {
            console.error("Target folder does not exist!");
            setErrorMessage("Target folder does not exist!"); // Set error message if folder doesn't exist
            return;
        }

        let totalSize = files.reduce((acc, file) => acc + file.size, 0);
        let totalUploaded = 0;

        for (let file of files) {
            const fileSize = file.size;
            const CHUNK_SIZE = 1 * 1024 * 1024; // 1MB

            const fileChecksum = await computeChecksum(file);

            let start = 0;
            while (start < fileSize) {
                const end = Math.min(start + CHUNK_SIZE, fileSize);
                const blob = file.slice(start, end);

                try {
                    const data = await uploadFileChunk(blob, file.name, start, end, fileSize, folder, fileChecksum);
                    setCurrentFileProgress(data.progress);

                    totalUploaded += (end - start);
                    const totalProgressPercentage = (totalUploaded / totalSize) * 100;
                    setTotalProgress(totalProgressPercentage);

                } catch (error) {
                    console.error("Error uploading chunk:", error.message);
                    if(error.message == "Upload would exceed your available storage space.")
                        setErrorMessage(error.message);
                    if(error.message.includes("You cannot upload files directly to the chatbots folder. Please upload to a subfolder."));
                        setErrorMessage(error.message);
                    break;
                }

                start += CHUNK_SIZE;
            }
        }
        if (typeof onUploadComplete === 'function') {
            onUploadComplete();
        }
    };

    return (
        <div id='fileUpload'>
            <div id='padding'>
                <input type="file" multiple={multiple} onChange={onFileChange} accept={ALLOWED_TYPES} />
                <button onClick={onUpload}>Upload</button>
                <p>Current File progress: {currentFileProgress}%</p>
                {multiple && <p>Total progress: {totalProgress.toFixed(2)}%</p>}
                {errorMessage && <p className="error">{errorMessage}</p>} {/* Display error message */}
            </div>
        </div>
    );
}

export default FileUploader;
