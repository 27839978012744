import React, { useState, useRef } from "react";
import { TextField, Button, CircularProgress, Input } from "@mui/material";
import WaveSurfer from "wavesurfer.js";
import { WaveFile } from "wavefile";
import RegionsPlugin from "wavesurfer.js/plugins/regions";
import "./VoiceSynthesis.css";

const VoiceSynthesis = () => {
    const [recording, setRecording] = useState(false);
    const [audioBlob, setAudioBlob] = useState(null);
    const [uploadedAudio, setUploadedAudio] = useState(null);
    const [trimmedAudio, setTrimmedAudio] = useState(null);
    const [generatedAudio, setGeneratedAudio] = useState(null);
    const [text, setText] = useState("");
    const [loading, setLoading] = useState(false);
    const [wavesurfer, setWavesurfer] = useState(null);
    const [startTime, setStartTime] = useState(0);
    const [endTime, setEndTime] = useState(null);
    const mediaRecorder = useRef(null);

    const handleRecordStart = async () => {
        try {
            // Check if the device has a microphone
            const devices = await navigator.mediaDevices.enumerateDevices();
            const hasMicrophone = devices.some((device) => device.kind === "audioinput");

            if (!hasMicrophone) {
                alert("No microphone found on this device. Please connect a microphone.");
                return;
            }

            // Request microphone access
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            const recorder = new MediaRecorder(stream);
            mediaRecorder.current = recorder;

            const chunks = [];
            recorder.ondataavailable = (e) => chunks.push(e.data);
            recorder.onstop = () => {
                const blob = new Blob(chunks, { type: "audio/wav" });
                setAudioBlob(blob);
                initializeWaveSurfer(blob);
            };

            recorder.start();
            setRecording(true);
        } catch (error) {
            if (error.name === "NotAllowedError") {
                alert("Microphone access denied. Please allow microphone access in your browser settings.");
            } else if (error.name === "NotFoundError") {
                alert("No microphone detected. Please connect a microphone to use this feature.");
            } else {
                console.error("Error accessing microphone:", error);
            }
        }
    };

    const handleRecordStop = () => {
        if (mediaRecorder.current) {
            mediaRecorder.current.stop();
            setRecording(false);
        }
    };


    const handleUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            setUploadedAudio(file);
            initializeWaveSurfer(file);
        }
    };

    const initializeWaveSurfer = (file) => {
        const fileUrl = URL.createObjectURL(file);
    
        // Create WaveSurfer instance with RegionsPlugin
        const ws = WaveSurfer.create({
            container: "#waveform",
            waveColor: "#ddd",
            progressColor: "#ff6f61",
            plugins: [
                RegionsPlugin.create({
                    dragSelection: true, // Enable drag selection
                }),
            ],
        });
    
        // Listen for the 'ready' event of WaveSurfer
        ws.on("ready", () => {
            const duration = ws.getDuration();
            setEndTime(duration);
    
            // Access the RegionsPlugin after it is initialized
            const regionsPlugin = ws.getActivePlugins()?.regions;
    
            if (regionsPlugin) {
                // Add an initial region for trimming
                regionsPlugin.addRegion({
                    start: 0,
                    end: duration,
                    color: "rgba(255, 111, 97, 0.3)",
                    drag: true,
                    resize: true,
                });
    
                // Add event listener for region updates
                regionsPlugin.on("region-updated", (region) => {
                    setStartTime(region.start);
                    setEndTime(region.end);
                });
            } else {
                console.error("RegionsPlugin is not available");
            }
        });
    
        ws.load(fileUrl);
        setWavesurfer(ws);
    };
    

    const handleTrim = async () => {
        if (wavesurfer) {
            const region = wavesurfer.regions.list[Object.keys(wavesurfer.regions.list)[0]];
            if (region) {
                const audioBuffer = await wavesurfer.backend.buffer;
                const trimmedBlob = await trimAudio(region.start, region.end, audioBuffer);
                setTrimmedAudio(trimmedBlob);
                initializeWaveSurfer(trimmedBlob);
            }
        }
    };


    const trimAudio = async (start, end, audioBuffer) => {
        const audioContext = new AudioContext();
        const trimmedLength = Math.floor((end - start) * audioBuffer.sampleRate);
        const trimmedBuffer = audioContext.createBuffer(
            audioBuffer.numberOfChannels,
            trimmedLength,
            audioBuffer.sampleRate
        );

        for (let i = 0; i < audioBuffer.numberOfChannels; i++) {
            const channelData = audioBuffer
                .getChannelData(i)
                .slice(
                    Math.floor(start * audioBuffer.sampleRate),
                    Math.floor(end * audioBuffer.sampleRate)
                );
            trimmedBuffer.copyToChannel(channelData, i, 0);
        }

        const offlineContext = new OfflineAudioContext(
            trimmedBuffer.numberOfChannels,
            trimmedBuffer.length,
            trimmedBuffer.sampleRate
        );

        const source = offlineContext.createBufferSource();
        source.buffer = trimmedBuffer;
        source.connect(offlineContext.destination);
        source.start(0);

        const renderedBuffer = await offlineContext.startRendering();
        const wavBlob = await audioBufferToWav(renderedBuffer);
        return wavBlob;
    };

    const audioBufferToWav = (buffer) => {
        return new Promise((resolve) => {
            const wav = new WaveFile();

            // Convert AudioBuffer to raw PCM data
            const channelData = buffer.getChannelData(0); // Use only the first channel
            const sampleRate = buffer.sampleRate;
            const bitDepth = 16; // 16-bit PCM

            // Write WAV using wavefile
            wav.fromScratch(1, sampleRate, bitDepth, channelData);

            // Resolve as Blob
            const wavBlob = new Blob([wav.toBuffer()], { type: "audio/wav" });
            resolve(wavBlob);
        });
    };

    const generateAudio = async () => {
        setLoading(true);
        try {
            const formData = new FormData();
            formData.append("text", text);
            formData.append("language", "de");

            const referenceAudio = trimmedAudio || uploadedAudio || audioBlob;
            if (referenceAudio) {
                formData.append("speaker_wav", referenceAudio);
            }

            const response = await fetch("http://localhost:8080/tts", {
                method: "POST",
                body: formData,
            });

            if (response.ok) {
                const blob = await response.blob();
                setGeneratedAudio(blob);
            } else {
                console.error("TTS API call failed", await response.text());
            }
        } catch (error) {
            console.error("Error generating audio:", error);
        } finally {
            setLoading(false);
        }
    };

    const downloadAudio = (audioBlob, fileName) => {
        const audioUrl = URL.createObjectURL(audioBlob);
        const link = document.createElement("a");
        link.href = audioUrl;
        link.download = fileName;
        link.click();
        URL.revokeObjectURL(audioUrl);
    };

    return (
        <div className="voice-synthesis-container">
            <h1>Voice Synthesis</h1>

            <div className="section">
                <h2>Record Your Voice</h2>
                <Button
                    variant="contained"
                    color={recording ? "secondary" : "primary"}
                    onClick={recording ? handleRecordStop : handleRecordStart}
                >
                    {recording ? "Stop Recording" : "Record Voice"}
                </Button>
            </div>

            <div className="section">
                <h2>Upload Audio</h2>
                <label htmlFor="audio-upload">
                    <Input
                        type="file"
                        id="audio-upload"
                        accept="audio/*"
                        onChange={handleUpload}
                    />
                </label>
            </div>

            <div id="waveform" className="waveform"></div>
            <div className="trim-controls">
                <TextField
                    label="Start Time (sec)"
                    type="number"
                    value={startTime || ""}
                    onChange={(e) => {
                        const newStartTime = parseFloat(e.target.value) || 0;
                        setStartTime(newStartTime);

                        if (wavesurfer) {
                            const region = wavesurfer.regions.list[Object.keys(wavesurfer.regions.list)[0]];
                            if (region) {
                                region.update({ start: newStartTime });
                            }
                        }
                    }}
                />
                <TextField
                    label="End Time (sec)"
                    type="number"
                    value={endTime || ""}
                    onChange={(e) => {
                        const newEndTime = parseFloat(e.target.value) || 0;
                        setEndTime(newEndTime);

                        if (wavesurfer) {
                            const region = wavesurfer.regions.list[Object.keys(wavesurfer.regions.list)[0]];
                            if (region) {
                                region.update({ end: newEndTime });
                            }
                        }
                    }}
                />
                <Button
                    variant="outlined"
                    color="secondary"
                    onClick={handleTrim}
                    disabled={!wavesurfer}
                >
                    Trim Audio
                </Button>
            </div>


            <div className="section">
                <h2>Generate Synthetic Voice</h2>
                <TextField
                    label="Enter text for synthesis"
                    variant="outlined"
                    fullWidth
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                />
                <Button
                    variant="contained"
                    color="primary"
                    onClick={generateAudio}
                    disabled={loading || !text.trim()}
                >
                    {loading ? <CircularProgress size={24} /> : "Generate Audio"}
                </Button>
            </div>

            <div className="section">
                {audioBlob && (
                    <>
                        <h3>Recorded Audio:</h3>
                        <audio controls src={URL.createObjectURL(audioBlob)}></audio>
                        <Button onClick={() => downloadAudio(audioBlob, "recorded_audio.wav")}>
                            Download
                        </Button>
                    </>
                )}
                {uploadedAudio && (
                    <>
                        <h3>Uploaded Audio:</h3>
                        <audio controls src={URL.createObjectURL(uploadedAudio)}></audio>
                        <Button onClick={() => downloadAudio(uploadedAudio, "uploaded_audio.wav")}>
                            Download
                        </Button>
                    </>
                )}
                {trimmedAudio && (
                    <>
                        <h3>Trimmed Audio:</h3>
                        <audio controls src={URL.createObjectURL(trimmedAudio)}></audio>
                        <Button onClick={() => downloadAudio(trimmedAudio, "trimmed_audio.wav")}>
                            Download
                        </Button>
                    </>
                )}
                {generatedAudio && (
                    <>
                        <h3>Generated Audio:</h3>
                        <audio controls src={URL.createObjectURL(generatedAudio)}></audio>
                        <Button onClick={() => downloadAudio(generatedAudio, "synthesized_audio.wav")}>
                            Download
                        </Button>
                    </>
                )}
            </div>
        </div>
    );
};

export default VoiceSynthesis;
