import React from "react";
import { Container, Typography } from "@mui/material";
import "./TermsOfService.css";

const TermsOfService = () => {
    return (
        <Container className="terms-container">
            <Typography variant="h4" gutterBottom>
                Terms of Service
            </Typography>
            <Typography variant="body1" paragraph>
                Welcome to our platform. By accessing or using our services, you agree to comply with and be bound by the following terms and conditions. Please read them carefully.
            </Typography>

            <Typography variant="h5" gutterBottom>
                1. Acceptance of Terms
            </Typography>
            <Typography variant="body1" paragraph>
                By using this platform, you agree to these Terms of Service. If you do not agree, you may not use the platform. We reserve the right to update these terms at any time, and your continued use of the platform signifies your acceptance of any changes.
            </Typography>

            <Typography variant="h5" gutterBottom>
                2. Use of the Service
            </Typography>
            <Typography variant="body1" paragraph>
                Our services, including chatbots, storage capabilities, and drives, are designed to facilitate communication, storage, and sharing. You agree not to misuse these services by:
            </Typography>
            <ul>
                <li>Uploading malicious, illegal, or harmful content.</li>
                <li>Attempting to hack, overload, or disrupt the platform.</li>
                <li>Sharing your account credentials with unauthorized parties.</li>
                <li>Using the platform for activities that violate the law or these terms.</li>
            </ul>

            <Typography variant="body1" paragraph>
                We reserve the right to suspend or terminate your account if we believe you have violated these terms.
            </Typography>

            <Typography variant="h5" gutterBottom>
                3. Chatbot Interactions and Data Logging
            </Typography>
            <Typography variant="body1" paragraph>
                Our chatbot feature is designed to assist with various tasks. By using the chatbot, you agree that:
            </Typography>
            <ul>
                <li>Chat logs may be stored to enhance service quality.</li>
                <li>We do not guarantee that chatbot responses will always be accurate, relevant, or free from errors.</li>
                <li>You will not abuse the chatbot by submitting inappropriate or harmful content.</li>
            </ul>

            <Typography variant="h5" gutterBottom>
                4. User-Generated Content
            </Typography>
            <Typography variant="body1" paragraph>
                You retain ownership of the files and content you upload. However, by using the platform, you grant us the right to store and display your content within the context of providing our services. You are responsible for ensuring that you have the legal rights to upload any content, and you agree to not upload any:
            </Typography>
            <ul>
                <li>Copyrighted material without permission.</li>
                <li>Illegal or harmful content.</li>
                <li>Private data of other individuals without their consent.</li>
            </ul>

            <Typography variant="h5" gutterBottom>
                5. Data Storage and Security
            </Typography>
            <Typography variant="body1" paragraph>
                We offer data storage and drive services, and while we strive to protect your data, we cannot guarantee complete security. You are responsible for backing up important data. In the event of a security breach, we will notify affected users and take immediate steps to secure the platform.
            </Typography>
            <Typography variant="body1" paragraph>
                You agree that we are not responsible for any data loss or unauthorized access unless it results from our gross negligence.
            </Typography>

            <Typography variant="h5" gutterBottom>
                6. Intellectual Property
            </Typography>
            <Typography variant="body1" paragraph>
                All platform content, including logos, designs, and trademarks, is our property or the property of our licensors. You may not use, copy, or distribute any platform content without our prior written consent.
            </Typography>

            <Typography variant="h5" gutterBottom>
                7. Account Security
            </Typography>
            <Typography variant="body1" paragraph>
                You are responsible for maintaining the security of your account, including keeping your password confidential. If you believe your account has been compromised, notify us immediately. We are not responsible for any unauthorized activity that occurs as a result of your failure to secure your account.
            </Typography>

            <Typography variant="h5" gutterBottom>
                8. Limitations of Liability
            </Typography>
            <Typography variant="body1" paragraph>
                We provide the platform on an "as-is" basis and do not guarantee that it will be free from interruptions, errors, or security vulnerabilities. To the fullest extent permitted by law, we disclaim all liability for any damages arising out of your use of the platform, including lost data, loss of profits, or damage to your devices.
            </Typography>

            <Typography variant="h5" gutterBottom>
                9. User Rights and Data Deletion
            </Typography>
            <Typography variant="body1" paragraph>
                You have the right to access your data and request its deletion at any time by contacting <a href="mailto:andreas.reich@uni-hohenheim.de">andreas.reich@uni-hohenheim.de</a>. Upon receiving a deletion request, we will promptly remove your data from our systems, except where retention is required by law or for legitimate business purposes.
            </Typography>

            <Typography variant="h5" gutterBottom>
                10. Termination of Service
            </Typography>
            <Typography variant="body1" paragraph>
                We reserve the right to suspend or terminate your access to the platform at any time for any reason, including but not limited to, violation of these terms or misuse of the service. Upon termination, you will no longer have access to your account, and we may delete all associated data.
            </Typography>

            <Typography variant="h5" gutterBottom>
                11. Governing Law and Dispute Resolution
            </Typography>
            <Typography variant="body1" paragraph>
                These Terms of Service are governed by the laws of Germany. Any disputes arising out of or related to these terms or the platform will be resolved in the courts of Stuttgart, Germany.
            </Typography>

            <Typography variant="h5" gutterBottom>
                12. Contact Information
            </Typography>
            <Typography variant="body1" paragraph>
                If you have any questions about these terms or need assistance, you can contact us at <a href="mailto:andreas.reich@uni-hohenheim.de">andreas.reich@uni-hohenheim.de</a>.
            </Typography>
        </Container>
    );
};

export default TermsOfService;
