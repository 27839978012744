import React, { useState, useContext } from 'react';
import { AuthContext } from '../../AuthContext';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, CircularProgress, Typography, Container, Box, IconButton, InputAdornment } from '@mui/material';
import CasinoIcon from '@mui/icons-material/Casino';
import './ImageGenerationPage.css';

const ImageGenerationPage = () => {
    const { authenticatedUser } = useContext(AuthContext);
    const navigate = useNavigate();
    const [prompt, setPrompt] = useState('');
    const [negativePrompt, setNegativePrompt] = useState('');
    const [seed, setSeed] = useState('');
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState(null);
    const [error, setError] = useState(null);

    // If the user is not authenticated, redirect them to login page
    if (!authenticatedUser) {
        navigate('/login');
        return null;
    }

    const handleRollSeed = () => {
        setSeed(Math.floor(Math.random() * 1000000));
    };

    const handleGenerateImage = async (event) => {
        event.preventDefault();
        setLoading(true);
        setError(null);
        setImageUrl(null);

        console.log("Prompt:", prompt);
        console.log("Negative Prompt:", negativePrompt);
        console.log("Seed:", seed);

        try {
            const response = await fetch('http://localhost:5001/queue', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    data: {
                        prompt: prompt,
                        negative_prompt: negativePrompt,
                        seed: seed,
                        priority: 'low'
                    }
                })
            });

            if (!response.ok) {
                throw new Error('Failed to generate image');
            }

            const imageBlob = await response.blob();
            setImageUrl(URL.createObjectURL(imageBlob));

        } catch (err) {
            console.error('Error generating image:', err);
            setError('Failed to generate image. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const handleDownloadImage = () => {
        if (imageUrl) {
            const link = document.createElement('a');
            link.href = imageUrl;
            link.download = 'generated-image.png';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    };

    return (
        <Container maxWidth="md" className="imageGenerationContainer">

            <Box mb={2} p={2} border={1} borderColor="grey.500" borderRadius={4} bgcolor="grey.100">
                <Typography variant="body1" color="textSecondary">
                    Please note: The image generation model used here is quite small and limited due to hardware constraints.
                    The quality of the generated images could be significantly improved if we allocated more AI hardware.
                </Typography>
            </Box>

            <Typography variant="h4" gutterBottom>
                Generate an Image
            </Typography>

            <form onSubmit={handleGenerateImage} className="imageGenerationForm">
                {/* Prompt Input */}
                <TextField
                    label="Enter Prompt (comma separated)"
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={3}
                    margin="normal"
                    value={prompt}
                    onChange={(e) => setPrompt(e.target.value)}
                    helperText="Describe the image you want to generate. Use comma-separated values for better control."
                    required
                />

                {/* Negative Prompt Input */}
                <TextField
                    label="Negative Prompt (optional, comma separated)"
                    variant="outlined"
                    fullWidth
                    multiline
                    rows={2}
                    margin="normal"
                    value={negativePrompt}
                    onChange={(e) => setNegativePrompt(e.target.value)}
                    helperText="Specify what you want to avoid in the image. Use comma-separated values."
                />

                {/* Seed Input with Roll Button */}
                <TextField
                    label="Seed"
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    value={seed}
                    onChange={(e) => setSeed(e.target.value)}
                    helperText="Set a seed for reproducible results, or roll for a random seed."
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={handleRollSeed}>
                                    <CasinoIcon />
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />

                <Box mt={2} mb={2} width="100%">
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        disabled={loading}
                        fullWidth
                    >
                        {loading ? <CircularProgress size={24} /> : 'Generate'}
                    </Button>
                </Box>
            </form>

            {error && (
                <Typography color="error" variant="body1" className="error">
                    {error}
                </Typography>
            )}

            {imageUrl && (
                <Box mt={4} className="imageContainer">
                    <Typography variant="h6">
                        Generated Image:
                    </Typography>
                    <img src={imageUrl} alt="Generated" className="generatedImage" />

                    <Box mt={2}>
                        <Button variant="contained" color="secondary" onClick={handleDownloadImage}>
                            Download Image
                        </Button>
                    </Box>
                </Box>
            )}
        </Container>
    );
};

export default ImageGenerationPage;
