import React from 'react';
import { Container, Typography, Paper } from '@mui/material';

const Imprint = () => {
    return (
        <Container className="privacy-policy-container">
            <Typography variant="h4" gutterBottom>
                Imprint
            </Typography>
            <Typography variant="body1" paragraph>
                This website is operated by:
            </Typography>
            <Typography variant="body1" paragraph>
                Universität Hohenheim<br />
                Address: Schloss Hohenheim 1, 70599 Stuttgart, Germany<br />
                Phone: +49 711 459 0<br />
                General University Email: info@uni-hohenheim.de
            </Typography>
            <Typography variant="body1" paragraph>
                Responsible for content according to § 55 Abs. 2 RStV:<br />
                Andreas Reich
                You can contact me at <a href="mailto:andreas.reich@uni-hohenheim.de">andreas.reich@uni-hohenheim.de</a>.
            </Typography>
            <Typography variant="body1" paragraph>
                Legal notice: This is a project under the guidance of Universität Hohenheim, funded by Stiftung Innovation Hochschullehre.
            </Typography>
        </Container>
    );
};

export default Imprint;
