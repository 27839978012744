import React, { createContext, useState, useEffect } from 'react';

export const ChatbotSubscriptionContext = createContext();

export const ChatbotSubscriptionProvider = ({ children }) => {
  const [chatbots, setChatbots] = useState([]);
  const [subscribedChatbots, setSubscribedChatbots] = useState([]);
  const token = localStorage.getItem('token'); // Retrieve token from localStorage

  // Fetch public chatbots from API
  const fetchChatbots = async () => {
    try {
      const response = await fetch('https://dellfi.serv.uni-hohenheim.de/backend/chatbots/public', {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setChatbots(data);
    } catch (error) {
      console.error('Failed to fetch chatbots', error);
    }
  };

  // Fetch subscribed chatbots from API
  const fetchSubscribedChatbots = async () => {
    try {
      const response = await fetch('https://dellfi.serv.uni-hohenheim.de/backend/chatbots/subscriptions', {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch subscribed chatbots');
      }
      const data = await response.json();
      setSubscribedChatbots(data);
    } catch (error) {
      console.error('Failed to fetch subscribed chatbots', error);
    }
  };

  useEffect(() => {
    fetchChatbots();
    fetchSubscribedChatbots();
  }, [token]);

  return (
    <ChatbotSubscriptionContext.Provider
      value={{
        chatbots,
        subscribedChatbots,
        fetchChatbots,
        fetchSubscribedChatbots,
      }}
    >
      {children}
    </ChatbotSubscriptionContext.Provider>
  );
};
