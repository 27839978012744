import React, { useState, useEffect } from 'react';
import { Modal, Box, Button, TextField, FormControlLabel, Switch, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import './ChatbotSettingsModal.css';

const ChatbotSettingsModal = ({ open, handleClose, currentPath, token }) => {

    currentPath = currentPath.replace('chatbots/', '').split('/')[0];

    const [name, setName] = useState('');
    const [chatbotName, setChatbotName] = useState('');
    const [chatbotPublic, setChatbotPublic] = useState(false);
    const [chatbotLink, setChatbotLink] = useState('');
    const [chatbotDescription, setChatbotDescription] = useState('');
    const [chatbotPath, setChatbotPath] = useState(currentPath);
    const [systemPrompt, setSystemPrompt] = useState('');
    const [externalLinks, setExternalLinks] = useState(['']);
    const [chatbotId, setChatbotId] = useState(null); // Store chatbot ID for editing

    // Fetch chatbot details when the modal opens
    useEffect(() => {
        if (open && currentPath) {
            fetchChatbotData(currentPath);
        }
    }, [open, currentPath]);

    const fetchChatbotData = async (path) => {
        try {
            const url = `https://dellfi.serv.uni-hohenheim.de/backend/chatbot/path/${path}`;
            const response = await fetch(url, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });

            if (response.ok) {
                const data = await response.json();
                setName(data.name);
                setChatbotName(data.chatbotName);
                setChatbotPublic(data.public);
                setChatbotLink(`${window.location.origin}/bot/${data.link}`);
                setChatbotDescription(data.shortDescription);
                setChatbotPath(data.chatbotPath);
                setSystemPrompt(data.systemPrompt);
                setExternalLinks(data.externalLinks);
                setChatbotId(data.id); // Store chatbot ID for editing
            } else {
                console.error('Error fetching chatbot data:', response.statusText);
            }
        } catch (error) {
            console.error('Error fetching chatbot:', error);
        }
    };

    const handleAddLink = () => {
        setExternalLinks([...externalLinks, '']);
    };

    const handleRemoveLink = (index) => {
        const updatedLinks = externalLinks.filter((_, i) => i !== index);
        setExternalLinks(updatedLinks);
    };

    const handleLinkChange = (index, value) => {
        const updatedLinks = externalLinks.map((link, i) => (i === index ? value : link));
        setExternalLinks(updatedLinks);
    };

    const handleSave = async () => {
        try {
            const url = `https://dellfi.serv.uni-hohenheim.de/backend/chatbot/edit/${chatbotId}`;
            const response = await fetch(url, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    newFolderName: chatbotName,
                    systemPrompt,
                    public: chatbotPublic,
                    externalLinks,
                    shortDescription: chatbotDescription,
                }),
            });

            if (response.ok) {
                const updatedChatbot = await response.json();
                console.log('Chatbot updated successfully:', updatedChatbot);
                handleClose(); // Close the modal after saving
            } else {
                console.error('Error saving chatbot data:', response.statusText);
            }
        } catch (error) {
            console.error('Error saving chatbot:', error);
        }
    };

    return (
        <Modal open={open} onClose={handleClose} aria-labelledby="modal-title" aria-describedby="modal-description">
            <Box className="chatbot-modal-box">
                <IconButton onClick={handleClose} className="chatbot-close-button">
                    <CloseIcon />
                </IconButton>

                <h2>Chatbot Settings</h2>

                <TextField
                    label="Chatbot Name"
                    value={name}
                    fullWidth
                    margin="normal"
                    InputProps={{
                        readOnly: true,
                    }}
                />

                <FormControlLabel
                    control={<Switch checked={chatbotPublic} onChange={(e) => setChatbotPublic(e.target.checked)} />}
                    label="Public"
                />

                {chatbotPublic && (
                    <TextField
                        label="Chatbot Link"
                        value={chatbotLink}
                        fullWidth
                        margin="normal"
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                )}

                <TextField
                    label="Short Description"
                    value={chatbotDescription}
                    onChange={(e) => setChatbotDescription(e.target.value)}
                    fullWidth
                    margin="normal"
                    multiline
                    inputProps={{ maxLength: 300 }}
                    className="chatbot-description-field"
                />

                <TextField
                    label="Chatbot Path"
                    value={chatbotPath}
                    fullWidth
                    margin="normal"
                    InputProps={{
                        readOnly: true,
                    }}
                />


                <TextField
                    label="Assistant Name"
                    value={chatbotName}
                    onChange={(e) => setChatbotName(e.target.value)}
                    fullWidth
                    margin="normal"
                    inputProps={{ maxLength: 30 }}
                />

                <TextField
                    label="System Prompt"
                    value={systemPrompt}
                    onChange={(e) => setSystemPrompt(e.target.value)}
                    fullWidth
                    margin="normal"
                    multiline
                    inputProps={{ maxLength: 2000 }}
                    className="chatbot-system-prompt-field"
                />

                <h4>External Links</h4>
                {externalLinks.map((link, index) => (
                    <div key={index} className="chatbot-external-link-row">
                        <TextField
                            label={`Link ${index + 1}`}
                            value={link}
                            onChange={(e) => handleLinkChange(index, e.target.value)}
                            fullWidth
                            margin="normal"
                        />
                        <IconButton onClick={() => handleRemoveLink(index)}>
                            <DeleteIcon />
                        </IconButton>
                    </div>
                ))}
                <Button onClick={handleAddLink} startIcon={<AddIcon />} className="chatbot-add-link-button">
                    Add Link
                </Button>

                <Button variant="contained" color="primary" className="chatbot-save-button" onClick={handleSave}>
                    Save
                </Button>
            </Box>
        </Modal>
    );
};

export default ChatbotSettingsModal;
