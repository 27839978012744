import React from "react";
import { Container, Typography } from "@mui/material";
import "./PrivacyPolicy.css";

const PrivacyPolicy = () => {
    return (
        <Container className="privacy-policy-container">
            <Typography variant="h4" gutterBottom>
                Data Protection Privacy Policy
            </Typography>
            <Typography variant="body1" paragraph>
                We take the protection of your personal data very seriously. This privacy policy outlines the types of information we collect, how we use it, and the measures we take to ensure your data remains secure.
            </Typography>
            
            <Typography variant="h5" gutterBottom>
                1. Data Collection and Processing
            </Typography>
            <Typography variant="body1" paragraph>
                We collect and process personal data only as necessary to provide our services. The data we collect includes:
            </Typography>
            <ul>
                <li>Personal information provided during registration (e.g., name, email address).</li>
                <li>Data generated through the use of our services, such as chat interactions, uploaded files, and search queries.</li>
                <li>Technical data such as IP address, browser type, and usage information through cookies (see "Cookies" section).</li>
            </ul>
            <Typography variant="body1" paragraph>
                We process your personal data for the following purposes:
            </Typography>
            <ul>
                <li>To provide and enhance the functionality of our services.</li>
                <li>To improve user experience and service performance through data analysis.</li>
                <li>To comply with legal obligations and ensure the security of our systems.</li>
            </ul>

            <Typography variant="h5" gutterBottom>
                2. Cookies and Tracking Technologies
            </Typography>
            <Typography variant="body1" paragraph>
                This website uses cookies to improve your experience and to analyze the usage of our services. Cookies are small files stored on your device. You can control the use of cookies through your browser settings. However, disabling cookies may limit some functionality of our website.
            </Typography>
            <Typography variant="body1" paragraph>
                We use both session cookies (which are deleted after you close your browser) and persistent cookies (which remain on your device for a specified period). These cookies help us:
            </Typography>
            <ul>
                <li>Keep you logged in during a session.</li>
                <li>Remember your preferences for future visits.</li>
                <li>Analyze how users navigate through the site to improve service delivery.</li>
            </ul>

            <Typography variant="h5" gutterBottom>
                3. Chatbot Interactions and Logging
            </Typography>
            <Typography variant="body1" paragraph>
                Our platform includes chatbot interactions where users may engage in conversations or ask questions. Some interactions may be logged to improve the chatbot's performance and ensure the quality of service. These logs may include the content of the conversation, timestamps, and metadata associated with the interaction.
            </Typography>
            <Typography variant="body1" paragraph>
                Users should be aware that while chat logs are used solely for improving the service, they can be requested for review, editing, or deletion upon request. Please contact us at <a href="mailto:andreas.reich@uni-hohenheim.de">andreas.reich@uni-hohenheim.de</a> to make such a request.
            </Typography>

            <Typography variant="h5" gutterBottom>
                4. Data Storage and Drive Capabilities
            </Typography>
            <Typography variant="body1" paragraph>
                Our platform offers storage and drive capabilities where users can upload, store, and share files. All data uploaded is subject to our data protection policies:
            </Typography>
            <ul>
                <li>Files uploaded to the platform are stored securely using industry-standard encryption and access controls.</li>
                <li>Users retain full rights over their files, but must be aware that sharing files or linking them in chatbot interactions may expose them to third parties, depending on the privacy settings applied.</li>
                <li>File uploads may contain metadata, which may be logged for performance tracking or security reasons. Users should ensure their files do not contain sensitive personal data unless absolutely necessary.</li>
            </ul>
            <Typography variant="body1" paragraph>
                If you wish to delete your stored files or have any concerns about the security of your data, you can request an audit or deletion of your data by emailing <a href="mailto:andreas.reich@uni-hohenheim.de">andreas.reich@uni-hohenheim.de</a>.
            </Typography>

            <Typography variant="h5" gutterBottom>
                5. Your Rights
            </Typography>
            <Typography variant="body1" paragraph>
                You have the following rights with regard to your personal data:
            </Typography>
            <ul>
                <li>The right to access the personal data we hold about you.</li>
                <li>The right to correct or update any inaccurate data.</li>
                <li>The right to request deletion of your data.</li>
                <li>The right to object to or restrict the processing of your data.</li>
                <li>The right to data portability, allowing you to move your data to another service provider.</li>
            </ul>
            <Typography variant="body1" paragraph>
                To exercise any of these rights, or if you have any questions about your personal data, contact us at <a href="mailto:andreas.reich@uni-hohenheim.de">andreas.reich@uni-hohenheim.de</a>.
            </Typography>

            <Typography variant="h5" gutterBottom>
                6. Data Sharing and Metadata Collection
            </Typography>
            <Typography variant="body1" paragraph>
                We do not sell or rent your personal data to third parties. However, certain data, including metadata, may be shared within the platform when users interact through shared drives or chatbots. You are advised to carefully review your sharing settings to control what is visible to others.
            </Typography>
            <Typography variant="body1" paragraph>
                We may also collect metadata (e.g., timestamps, device types) for operational purposes. While you retain full rights to your data, by using the service, you acknowledge that your metadata may be stored and used to improve the platform. This data will not be used for any purpose beyond improving the user experience or addressing system performance issues.
            </Typography>

            <Typography variant="h5" gutterBottom>
                7. No Warranty and Limitation of Liability
            </Typography>
            <Typography variant="body1" paragraph>
                While we take all necessary steps to protect your data, we cannot guarantee that the service will always be secure, error-free, or meet your requirements. You agree to use the service at your own risk, and we disclaim all liability for any damages that may result from the use or inability to use the platform.
            </Typography>

            <Typography variant="h5" gutterBottom>
                8. Changes to This Privacy Policy
            </Typography>
            <Typography variant="body1" paragraph>
                We reserve the right to update this privacy policy from time to time to reflect changes in our services or legal obligations. We will notify users of significant changes via email or through our platform. Your continued use of the service constitutes acceptance of any updates to this policy.
            </Typography>

            <Typography variant="h5" gutterBottom>
                9. Contact Us
            </Typography>
            <Typography variant="body1" paragraph>
                If you have any questions about this privacy policy or how your data is handled, please contact our data protection officer at <a href="mailto:andreas.reich@uni-hohenheim.de">andreas.reich@uni-hohenheim.de</a>.
            </Typography>
        </Container>
    );
};

export default PrivacyPolicy;
