import { Document, Page, pdfjs } from 'react-pdf';
import { useEffect, useState, useRef } from 'react';
import './PDFViewer.css';

pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;


const PDFViewer = ({ file, setTotalPages, currentPage, pageRefs, desiredPage, setDesiredPage, contentContainerRef }) => {
    const [numPages, setNumPages] = useState(null);
    const [loadedPages, setLoadedPages] = useState(Math.max(1, currentPage));
    const [containerWidth, setContainerWidth] = useState(null);
    const containerRef = useRef(null);

    const pagePositionsMutable = useRef([]);

    const updatePagePosition = (node, index) => {
        if (node) {
            pagePositionsMutable.current[index] = node.offsetTop;
        }
    };

    useEffect(() => {
        if (containerRef.current) {
            setContainerWidth(containerRef.current.offsetWidth);
        }
    
        const handleResize = () => {
            if (containerRef.current) {
                setContainerWidth(containerRef.current.offsetWidth);
            }
        };
    
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        pdfjs.GlobalWorkerOptions.workerSrc = new URL(
            'pdfjs-dist/build/pdf.worker.min.mjs',
            import.meta.url
        ).toString();
    }, []);

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
        setTotalPages(numPages);
    };

    useEffect(() => {
        if (numPages) {
            let newLoadValue;

            if (desiredPage && desiredPage > loadedPages) {
                const minPage = Math.max(desiredPage - 3, 1);
                const maxPage = Math.min(desiredPage + 3, numPages);
                
                newLoadValue = Math.max(minPage, maxPage);
            } else if (currentPage <= numPages) {
                newLoadValue = Math.min(numPages, currentPage + 5);
            }

            setLoadedPages(newLoadValue);
        }
    }, [currentPage, numPages, desiredPage]);

    // Jump to the desired page once it's loaded.
    useEffect(() => {
        const intervalId = setInterval(() => {
            if (desiredPage && pageRefs.current[desiredPage - 1] && contentContainerRef.current) {
                const desiredPageTop = pageRefs.current[desiredPage - 1].offsetTop;
                contentContainerRef.current.scrollTop = desiredPageTop;
                clearInterval(intervalId);
                setDesiredPage(null);
            }
        }, 100);
    
        return () => clearInterval(intervalId);
    }, [loadedPages, desiredPage, contentContainerRef]);
    

    const updatePageRefs = (node, index) => {
        pageRefs.current[index] = node;
    };

    return (
        <div className="pdfContainer"  ref={containerRef}>
            <Document
                file={file}
                onLoadSuccess={onDocumentLoadSuccess}
                onError={(error) => console.error("PDF error:", error)}
                externalLinkTarget="_blank"
            >
                {Array.from(
                    new Array(loadedPages),
                    (el, index) => (
                        <div key={index} ref={(el) => {
                            updatePageRefs(el, index);
                            updatePagePosition(el, index);
                        }}>
                            <Page pageNumber={index + 1} width={900}/>
                            <div className="pageNumber">{index + 1}</div>
                            {index !== numPages - 1 && <hr className="pageSeparator" />}
                        </div>
                    )
                )}
            </Document>
        </div>
    );
};

export default PDFViewer;
