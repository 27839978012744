import { useState, useEffect, useRef } from 'react';
import PDFViewer from './PDFViewer';
import './FileViewer.css';

const FileViewer = ({ fileType, file, fileName, setPreviewedFile, onDownload }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(null);
    const [desiredPage, setDesiredPage] = useState(null);
    const [textContent, setTextContent] = useState('');
    const [imageUrl, setImageUrl] = useState(null);
    const [audioUrl, setAudioUrl] = useState(null);
    const [videoUrl, setVideoUrl] = useState(null);

    const contentContainerRef = useRef(null);
    const pageRefs = useRef([]);
    const lastSetPageRef = useRef(null);
    const isPPTXPreview = fileType === 'pptx';
    fileType = isPPTXPreview ? 'pdf' : fileType;

    const blobToImageURL = (blob) => {
        return URL.createObjectURL(blob);
    };

    const blobToText = async (blob) => {
        return await blob.text();
    };

    const blobToMediaURL = (blob) => {
        return URL.createObjectURL(blob);
    };

    const isElementInViewport = (el) => {
        const rect = el.getBoundingClientRect();
        return (
            rect.top <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.bottom >= 0
        );
    };

    const handleScroll = () => {
        const visiblePageIndex = pageRefs.current.findIndex(isElementInViewport);
        //console.log('Visible page index:', visiblePageIndex);

        if (visiblePageIndex !== -1) {
            //console.log('Updating to page:', visiblePageIndex + 1);
            handleActualPageChange(visiblePageIndex + 1);
        }
    };



    const debounce = (func, wait) => {
        let timeout;
        return (...args) => {
            clearTimeout(timeout);
            timeout = setTimeout(() => func(...args), wait);
        };
    };


    useEffect(() => {
        const containerElement = contentContainerRef.current;
        const debouncedHandleScroll = debounce(handleScroll, 200);
        if (containerElement) {
            containerElement.addEventListener('scroll', debouncedHandleScroll);
            return () => containerElement.removeEventListener('scroll', debouncedHandleScroll);
        }
    }, []);



    const handleInputPageChange = (e) => {
        const newPage = Number(e.target.value);

        if (newPage >= 1 && newPage <= totalPages) {
            setCurrentPage(newPage);
            setDesiredPage(newPage);
        } else if (newPage < 1) {
            setCurrentPage(1);
            setDesiredPage(1);
        } else if (newPage > totalPages) {
            setCurrentPage(totalPages);
            setDesiredPage(totalPages);
        }

    };


    useEffect(() => {
        const originalStyle = window.getComputedStyle(document.body).position;
        document.body.style.position = 'fixed';
        document.body.style.width = '100%';
        return () => {
            document.body.style.position = originalStyle;
        };
    }, []);

    useEffect(() => {
        if (fileType === 'txt') {
            blobToText(file).then(text => setTextContent(text));
        } else if (['jpeg', 'jpg', 'png', 'PNG'].includes(fileType)) {
            setImageUrl(blobToImageURL(file));
        } else if (['mp3', 'wav'].includes(fileType)) {
            const url = blobToMediaURL(file);
            setAudioUrl(url);
            console.log('Audio URL:', url);
        } else if (['mp4', 'webm'].includes(fileType)) {
            const url = blobToMediaURL(file);
            setVideoUrl(url);
            console.log('Video URL:', url);
        }
    }, [file, fileType]);


    const handleActualPageChange = (page) => {
        if (page !== currentPage) {
            setCurrentPage(page);
            lastSetPageRef.current = page;
        }
    };

    const stopPropagation = (e) => {
        e.stopPropagation();
    };

    const preventScroll = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    let content, topBar;

    fileType = fileType.toLowerCase();

    switch (fileType) {
        case 'pdf':
            content = <PDFViewer file={file} setTotalPages={setTotalPages} currentPage={currentPage} pageRefs={pageRefs} desiredPage={desiredPage} setDesiredPage={setDesiredPage} contentContainerRef={contentContainerRef} />;
            topBar = (
                <div className="topBar" onClick={stopPropagation} onWheel={preventScroll}>
                    <input
                        type="number"
                        value={currentPage}
                        onInput={handleInputPageChange}
                        onBlur={() => { if (!currentPage) setCurrentPage(1); }}
                    />
                    /{totalPages}
                    <button onClick={() => onDownload(isPPTXPreview ? fileName.replace('-converted.pdf', '.pptx') : fileName)}>Download</button>
                   {isPPTXPreview ? <button onClick={() => onDownload(fileName)}>Download as PDF</button> : <></>} 
                </div>
            );
            break;
        case 'txt':
            content = <div className="textViewer">{textContent}</div>;
            topBar = (
                <div className="topBar" onClick={stopPropagation}>
                    <button onClick={() => onDownload(fileName)}>Download</button>
                </div>
            );
            break;
        case 'jpeg':
        case 'jpg':
        case 'png':
            content = <img src={imageUrl} alt="Preview" className="imageViewer" />;
            topBar = (
                <div className="topBar" onClick={stopPropagation}>
                    <button onClick={() => onDownload(fileName)}>Download</button>
                </div>
            );
            break;
        case 'mp3':
        case 'wav':
            content = audioUrl ? (
                <audio controls className="audioPlayer">
                    <source src={audioUrl} type={`audio/${fileType}`} />
                    Your browser does not support the audio element.
                </audio>
            ) : <div>Loading audio...</div>;
            topBar = (
                <div className="topBar" onClick={stopPropagation}>
                    <button onClick={() => onDownload(fileName)}>Download</button>
                </div>
            );
            break;
        case 'mp4':
        case 'webm':
            content = videoUrl ? (
                <video controls className="videoPlayer">
                    <source src={videoUrl} type={`video/${fileType}`} />
                    Your browser does not support the video element.
                </video>
            ) : <div>Loading video...</div>;
            topBar = (
                <div className="topBar" onClick={stopPropagation}>
                    <button onClick={() => onDownload(fileName)}>Download</button>
                </div>
            );
            break;
        default:
            content = <div>Unsupported file type.</div>;
            topBar = (
                <div className="topBar" onClick={stopPropagation}>
                    <button onClick={() => onDownload(fileName)}>Download</button>
                </div>
            );
    }


    return (
        <div className="fileViewerOverlay" onClick={() => setPreviewedFile(null)}>
            {topBar}
            <div className="fileViewerContent" onClick={stopPropagation} ref={contentContainerRef}>
                {content}
            </div>
            <button className="closeButton" onClick={() => setPreviewedFile(null)}>X</button>
        </div>
    );
}

export default FileViewer;

