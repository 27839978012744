import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../AuthContext';
import AdminDashboard from '../../components/AdminDashboard';
import Chat from '../../components/Chat';
import ChatbotSearch from '../../components/ChatbotSearch';
import SubscribedChatbots from '../../components/SubscribedChatbots';
import { Box, Grid, Button, Typography, Paper } from '@mui/material';
import { ChatbotSubscriptionProvider } from '../../components/ChatbotSubscriptionContext';

const Dashboard = () => {
  const navigate = useNavigate();
  const { authenticatedUser } = useContext(AuthContext);

  const isAdmin = authenticatedUser?.role === 'Admin';

  if (isAdmin) {
    return <AdminDashboard />;
  }

  return (
    <ChatbotSubscriptionProvider>
      <Box className="dashboardContainer" sx={{ padding: 4, backgroundColor: '#f0f0f0', minHeight: '100vh' }}>
        <Chat />
        <Typography className='dashboardTitle' variant="h4">
          Dashboard
        </Typography>

        <Grid container spacing={3} justifyContent="center">
          {/* Top Navigation Links */}
          <Grid item xs={12} md={10} lg={8}>
            <Grid container spacing={2}>
              {/* My Files Link */}
              <Grid item xs={6} md={6}>
                <Button
                  variant="contained"
                  fullWidth
                  onClick={() => navigate('/drive/')}
                  sx={{ padding: 2 }}
                >
                  My Files
                </Button>
              </Grid>
              {/* Create New Chatbot Link */}
              <Grid item xs={6} md={6}>
                <Button
                  variant="contained"
                  fullWidth
                  onClick={() => navigate('/drive/chatbots/?createFolder=true')}
                  sx={{ padding: 2 }}
                >
                  + Create New Chatbot
                </Button>
              </Grid>
            </Grid>
          </Grid>

          {/* Left - Subscribed Chatbots */}
          <Grid item xs={12} md={5}>
            <SubscribedChatbots />
          </Grid>

          {/* Right - Public Chatbot Search */}
          <Grid item xs={12} md={7}>
            <ChatbotSearch />
          </Grid>
        </Grid>
      </Box>
    </ChatbotSubscriptionProvider>
  );
};

export default Dashboard;
